










































































import {Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { GetCaoTypesAction, ToggleCaosAction } from '@/store/main/types';
import VButton from '@/components/VButton.vue';
import SubscriptionInfo from '@/interfaces/subscriptionInfo';
import * as accountTypes from '@/store/account/types';

@Component({
  components: { VButton },
})
export default class AccountCaos extends Vue {

  @Getter('subscriptionInfo', { namespace: 'account' }) private subscriptionInfo!: SubscriptionInfo;
  @Action('getCaoTypes', { namespace: 'main' }) private getCaoTypes!: GetCaoTypesAction;
  @Action('toggleCaos', { namespace: 'main' }) private toggleCaos!: ToggleCaosAction;
  @Action('subscriptionInfo', { namespace: 'account' })
  private getSubscriptionInfo!: accountTypes.SubscriptionInfoAction;

  private caoList: Array<{
    code: string, name: string, sbi: string, tool: boolean }> = [];
  private loadingCaos = false;
  private searchText = '';
  private pageLength = 20;
  private currentPage = 1;
  private selectAll = false;

  private created() {
    this.getSubscriptionInfo().then(() => {
    this.loadCaos();
    });
  }

  private loadCaos(): void {
    this.loadingCaos = true;
    this.getCaoTypes({ online: true }).then((response) => {
      this.caoList = response.map((elem) => {
        return {
        tool: this.subscriptionInfo.caoTypes.has(elem.code),
        ...elem,
        };
      }).sort((elem) => elem.tool ? -1 : 1);
      this.selectAll = this.caoList.findIndex((elem) => elem.tool === false) === -1;
    }).finally(() => {
      this.loadingCaos = false;
    });
  }

  private toggleAll() {
    this.caoList = this.caoList.map((elem) => ({ ...elem, tool: this.selectAll }));
    this.toggleCaos({
      cao_types: this.caoList,
    });
  }

  private toggleCao(cao: { code: string, tool: boolean}) {
    this.toggleCaos({ cao_types: [cao] });
    this.selectAll = this.caoList.findIndex((elem) => elem.tool === false) === -1;
  }

  get searchedCaos() {
    if (this.searchText.trim().length === 0) {
      return this.caoList;
    }
    return this.caoList.filter(
      (elem) => (elem.code + elem.name).toLowerCase().includes(this.searchText.trim().toLowerCase()),
    );
  }

  get paginatedCaos() {
    return this.searchedCaos.slice((this.currentPage - 1) * this.pageLength, this.currentPage * this.pageLength);
  }
}
